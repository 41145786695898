import React from 'react';
import { iosStoreUrl, androidStoreUrl } from '../../constants';

export default function QrCode() {
  const redirectToStore = () => {
    let url;
    if (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    ) {
      url = iosStoreUrl;
    } else if (/android/i.test(navigator.userAgent?.toLowerCase())) {
      url = androidStoreUrl;
    } else {
      url = 'https://www.spacetailor.io/';
    }

    if (typeof window !== 'undefined') {
      window.location = url; 
    }
  };

  setTimeout(redirectToStore, 25);
  if (typeof window !== 'undefined') {
    window.location = 'spacetailor://';
  }

  return (
    <>
      <p>redirecting...</p>
    </>
  );
}
